<template>
  <div >
    <topHead></topHead>

    <div class="mainCon">
      <div class="banner" v-if="userType == '3'">
        新疆维吾尔自治区房屋建筑和市政基础设施项目评标专家培训系统
      </div>
      <div class="banner" v-if="userType == '7'">
        培训学习系统
      </div>

      <div class="courseWrap" v-loading="loading"> 
        <div class="KS" v-if="isCompleteL">           
           <!-- <el-button type="primary" @click="goKS">去考试</el-button>

          <p style="font-size: 28px;color:red;font-weight: bold;margin:20px 0;" v-if="paymentStatus == 1">考试结束后两周内系统将成绩匹配到个人考试记录</p> -->
           <!-- <el-button type="primary" @click="gotest">去test</el-button> -->
        </div>
        <template  v-if="tableList.length > 0 ">
          <!-- <div class="title" >本期课程</div> -->
          <template v-for="(Pitem) in tableList" >
            <div style="text-align: center;">
              <el-button type="primary" size="middle" @click="goKS(Pitem.bokId)" v-if="isCompleteL" class="startBtn" style="">开始考试</el-button>
            </div>
            <p class="bokName">
              <span class="line"></span>
              <span class="bokNameText">{{ Pitem.bokName }}</span>
              <span class="ksBtn">
                <!-- <el-button type="primary" size="middle" @click="goKS(Pitem.bokId)" v-if="isCompleteL">去考试</el-button> -->
              </span>
            </p>
            <div class="listWrap" >
              <div class="list"  v-for="(item,index) in Pitem.lessonList" :key="index">
                <div class="item" @click="goLearn(item,Pitem.bokId)">
                  <!-- item.coverUrl -->
                  <img :src="item.coverUrl" alt="" class="itemImg">
                  <span class="name">名称：{{item.lessonName}}</span>
                  <p class="name">讲师：{{item.teacherName}}</p>
                  <span class="info">
                    <!-- <span class="time">6小时</span>   -->
                    <span class="btn">播放</span>
                    <el-tag type="info" v-if="item.state == 0 || item.state == null ">学习中</el-tag>
                    <el-tag type="success" v-if="item.state == 1">初审通过</el-tag>
                    <el-tag type="success" v-if="item.state == 2">学习完成</el-tag>
                    <el-tag type="warning" v-if="item.state == 3">初审不通过</el-tag>
                    <el-tag type="success" v-if="item.state == 5">复审通过</el-tag>
                    <el-tag type="danger" v-if="item.state == 6">复审不通过</el-tag>
                    
                    
                  </span>
                </div>
                
              </div>
            </div> 
        </template>  
        </template>
        <div class="tip" v-if=" showTip == true">
            {{ msg }}
        </div>

        <!-- <el-pagination class="mt20" background style="text-align:center;margin-top:20px;" v-if="(total>10)"
                    @current-change="handleCurrentChange" 
                    :current-page="params.pageNum" 
                    :total="total"></el-pagination> -->
      </div>
    </div>
    <el-dialog
            title="考试须知"
            :visible.sync="tipVisible"
            width="50%"
            :show-close="false"
            :center="true"
            :close-on-click-modal="false"
            >
            <ul>
                
                <li class="tipsItem" >
                  为保证测试成绩的真实、有效，系统启用多重防舞弊措施，违规操作将导致测试成绩无效，请仔细阅读并理解以下内容：
                </li>
                <li class="tipsItem" >
                  1、学员需自行准备具备摄像头功能的电脑并确保网络环境稳定，请使用谷歌浏览器；
                </li>
                <li class="tipsItem">
                  2、测试引入了身份认证和人脸识别功能，考前请清理浏览器全部缓存，请学员提前候考，完成考前准备工作；
                </li>
                <li class="tipsItem" >
                  3、不得由他人代替测试，如摄像头抓拍到非学员本人测试，测试按无效处理；
                </li>
                <li class="tipsItem" >
                  4、开始测试前请关闭与学习无关的弹窗软件，QQ、微信、360等都会触发离开测试页面提示；
                </li>
                <li class="tipsItem">
                  5、学员需全屏模式参与测试，如离开测试页面5次，系统会强制收卷；
                </li>
                <li class="tipsItem">
                  6、测试系统具备桌面共享及远程操作检测功能，请勿在测试过程中使用任何具备桌面共享或远程操作功能的软件，如检测到学员使用该类软件，系统将立刻终止测试并判定成绩为不合格。
                </li>
            </ul>
            <p style="margin-top:5px;width: 100%;height:40px;line-height:40px;background-color: #f1f1f1;color: #666;font-size: 18px;" v-if="Sencond > 0">请仔细阅读提示内容，<span style="color: #409eff;">{{Sencond}}</span>秒后方可关闭弹框,点击确认按钮进入考试页面</p>
            <span></span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tipVisible = false;goExam()" :disabled="isDisabled">确 定</el-button>
                <el-button  @click="tipVisible = false;clearTimeInt()" >取 消</el-button>
            </span>
        </el-dialog>

    <foot></foot>
    
  </div>
</template>

<script>
import foot  from '../components/footer.vue';
import topHead  from '../components/topHead.vue';
import netTool from '../api/netTool' 
//引进自定义组件
//头部面包屑导航栏
const trainUrl = process.env.VUE_APP_TRAIN_URL
const trainUrl2 = process.env.VUE_APP_TRAIN_URL2

export default {
  name:'home',
  //注册组件
  data(){
    return{
      tableList:[],
      params:{
        pageNum: 1,
        pageSize: 12,
        platformId: this.$store.state.pfId,
        category:localStorage.getItem('userType') == '3' ? 1: 2
      },
      // total:0,
      userInfo:{} ,
      userType:null,
      showTip:false,
      msg:'' ,  
      isCompleteL:false,
      KSMsg:'',
      loading:false,
      paymentStatus:null,
      isDisabled: true,
      Sencond: 60,//设置初始倒计时
      tipVisible:false,
      interval:null,
      bokId:null,
    }
  },
  components: {
    foot,
    topHead
  },
  async created(){
    // console.log('o0o0',this.$route.query)
    if(this.$route.query.en){
      localStorage.setItem('pfId',this.$route.query.en )
    }
    if(this.$route.query.uuid || localStorage.getItem('loginuuid')){
        this.token = this.$route.query.uuid || localStorage.getItem('loginuuid');
        localStorage.setItem('loginuuid',this.$route.query.uuid || localStorage.getItem('loginuuid') )
        // localStorage.setItem('pfId',this.$route.query.platformId)
        if(this.$route.query.userType){ // 如果地址栏有userType 就以地址栏的为准
          if(this.$route.query.userType == 3){
            // console.log('00',this.$route.query.userType,localStorage.getItem('userType'))
            localStorage.setItem('userType','3') 
            this.userType = '3'
            this.userInfo = await  this.$store.getUserInfo(this.token);
            this.getList()
            this.getKSWindow()
            this.getDetailInfo()
          }else if(this.$route.query.userType == 7){
            // console.log('11',this.$route.query.userType,localStorage.getItem('userType'))
            localStorage.setItem('userType','7') 
            this.userType = '7'
            this.userInfo = await  this.$store.getMemberInfo();
            this.getList()
            this.getKSWindow()
            // this.getDetailInfo()
          }
        }else if(localStorage.getItem('userType')){ // 如果地址栏没有userType 就以缓存的为准
          if(localStorage.getItem('userType') == 3){
            // console.log('缓存00',this.$route.query.userType,localStorage.getItem('userType'))
            localStorage.setItem('userType','3') 
            this.userType = '3'
            this.userInfo = await  this.$store.getUserInfo(this.token);
            this.getList()
            this.getKSWindow()
            this.getDetailInfo()
          }else if(localStorage.getItem('userType') == 7){
            // console.log('缓存11',this.$route.query.userType,localStorage.getItem('userType'))
            localStorage.setItem('userType','7') 
            this.userType = '7'
            this.userInfo = await  this.$store.getMemberInfo();
            this.getList()
            this.getKSWindow()
            // this.getDetailInfo()
          }
        }
        

        
    }else{
      // console.log('ooooo99999')
      this.$message({
          type: 'warning',
          message: '请您先登录！'
        });
        if(localStorage.getItem('userType') == '3'){
          window.location.href = trainUrl
        }else if(localStorage.getItem('userType') == '7'){
          window.location.href = trainUrl2
        }
        localStorage.removeItem('userType')
        
        return
    }
    document.title = "培训考试系统";
  },
  methods:{
    goLearn(item,bokId){
    // if(item.state == 1 || item.state == 5){
    //   this.$confirm('此视频已学习完成，不允许再次观看！', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning',
    //       center: true,
    //       showCancelButton:false,
    //     }).then(() => {
          
    //     }).catch(() => {
          
    //     });
    //     return
    // }
      this.$router.push({path:'/video',query:{videoUrl:item.videoUrl, bokId:bokId,id:item.lessonId,coverUrl:item.coverUrl,subjectId:item.subjectId,pause:item.pause,playback:item.playback,complete:item.complete}})
    },
    getSencond () {
        const that = this
        that.interval = window.setInterval(function () {
                --that.Sencond
                if (that.Sencond === 0) {
                    that.isDisabled = false
                    window.clearInterval(that.interval)
                }
        }, 1000)
    },
    clearTimeInt(){
      if (this.interval) {
            this.Sencond=60
            this.isDisabled = true;
            window.clearInterval(this.interval);
            this.interval = null;
        }
    },
    goExam(){
      this.$router.push({path:'/answer',query:{bokId:this.bokId}})
    },
    getList(){
      this.loading = true;
      netTool.publicExamChapterList(this.params).then(res => {
        // console.log(res)
        if(res.data){
          this.tableList = res.data;
        }
        
        if(res.status ==0){
          // this.total = res.data;
        }else if(res.status == 2004 ){
          this.showTip = true;
          this.msg = res.msg
        }
        this.loading = false;
      })
    },
    handleCurrentChange(val){
            this.params.pageNum = val;
            this.getList();
        },
    getKSWindow(){ //是否是考试窗口期
    
      // let params = new URLSearchParams();
      // params.append("platformId",this.$store.state.pfId);
      // params.append("category",localStorage.getItem('userType') == '3' ? 1: 2);
      // params.append("type",3);
      let params ={
        platformId:this.$store.state.pfId,
        category:localStorage.getItem('userType') == '3' ? 1: 2,
        type:3
      }
      netTool.getExaminWindow(params).then(res => {
        // console.log('res',res)
        if(res.status == 0){
          this.isCompleteL = res.data
        }else{
          this.KSMsg = res.msg
        }
       
      })
    },
    getDetailInfo(){
            
      let params = {
          "userId":this.userInfo.uuid,
      }
      netTool.userDetailInfo(params).then(res => {
          if(res.status == 0){
            this.paymentStatus = Number(res.data.paymentStatus)
          }
      })
    },
    goKS(id){
      this.tipVisible = true;
      this.getSencond()
      this.bokId = id
      // this.$router.push({path:'/answer',query:{bokId:id}})
      // window.location.href = 'https://kaoshi.wjx.top/vm/tOHUYq3.aspx'
    },
    gotest(){
      this.$router.push({path:'/test'})
    }
  },
  beforeRouteLeave(to, from, next) {
        if (this.interval) {
          window.clearInterval(this.interval);
            this.interval = null;
        }
        next();
  }
};
</script>

<style lang="scss" scoped="">
.topTitle{
  width: 100%;
  height:60px;
  line-height:60px;
  background: #232939;
  color: #fff;
  font-size:16px;
  display: flex;
  justify-content: space-between;
  .title{
    padding-left:20px;
  }
  .userInfo{
    padding-right:20px;
  }
}

.mainCon{
  margin:20px auto 0;
  width:1200px;
  height: auto;
  background-color: #fff;
  padding:20px;
  box-sizing: border-box;
  .banner{
    width:1160px;
    height:350px;
    background:url('../assets/images/banner.jpg') center center no-repeat;
    text-align: center;
    color: #fff;
    font-size: 36px;
    line-height: 350px;
  }
  .courseWrap{
    margin-top: 20px;
    .title{
      font-size: 18px;
      color: #333;
      text-align:left;
      margin-bottom:10px;
    }
    .startBtn{
      font-size: 28px;
      color: #eb560c;
      background-color: bisque;
      border-color: bisque;
      font-weight: bold;
      &:hover{
        background: #eb560c;
        color: bisque;
      }
    }
    .bokName{
      height: 50px;
      line-height:50px;
      display: flex;
      margin-top:60px;
      .line{
        display: inline-block;
        height:40px;
        width:8px;
        background: #409eff;
        margin-right:8px;
        // border-radius: 4px;;
      }
      .bokNameText{
        line-height:40px;
        font-size:24px;
        margin-right:10px;
      }
      .ksBtn{
        flex:1;
        height:50px;
        line-height:50px;
        border-bottom:1px solid #409eff;
        text-align:center;
      }
    }
    .listWrap{
       display: flex;
        align-items: center;
        /* 自动换行属性 */
        flex-wrap: wrap;

      .list{
       flex: 0 0 20%;
      //  margin:10px;
      }
      .item{
        
        background: rgb(236, 245, 255);
        padding:  10px;
        border-radius: 5px;
        margin: 15px 7px;

        .itemImg{
          width: 100%;
          height: 180px;
          border-radius: 5px;
          border:1px solid #c2d4e5
        }
        .name{
          // display: inline-block;
          height:35px;
          line-height:35px;
          color: #333;
          font-size:16px;
          
        }
        .info{
          display: flex;
          justify-content: space-between;
          .btn{
            color: #409eff;
            cursor: pointer;
            height: 32px;
            line-height: 32px;
          }
          .status{
            
          }
          .isComplate{
              color: green;
            }
        }
      }
    }
    .KS{
      // height: 40px;
      // line-height:40px;
      text-align:center;
      margin: 50px 0;
    }
  }
}
.tip{
  height: 160px;
    line-height: 160px;
    text-align: center;
    color: red;
}
.tips{
      height: 50px;
      line-height:50px;
      background: rgb(217, 236, 255);
      text-align: center;
      font-size: 28px;
      color: red;
      margin: 20px 0;
      font-weight: bold;
  }

 /deep/ .el-dialog__header {
    background: #1529e4;
    text-align: center;
    font-size: 30px;
  }
  /deep/ .el-dialog__title {
    color: aliceblue;
    font-size: 30px;
  }
  .tipsItem{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;;
  }

</style>
