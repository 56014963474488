<template>
    <div class="topTitle">
      <span class="title" v-if="userType == '3'">评标专家培训系统</span>  
      <span class="title" v-if="userType == '7'">培训学习系统</span>  
      <span class="userInfo">
        

        <span @click="goRecord" style="margin-left: 20px;cursor: pointer;"><i class="el-icon-aim"></i>学习记录</span>
        <span @click="goExaminRecord" style="margin-left: 20px;cursor: pointer;"><i class="el-icon-s-data"></i>考试记录</span>
        <el-dropdown trigger="click">
          <span @click="$router.push('/')" style="margin-left: 20px;cursor: pointer;"><span ><i class="el-icon-s-home"  style="cursor: pointer;"></i></span>
            欢迎你，{{ userInfo.personNo }}
          </span>
				<el-dropdown-menu slot="dropdown">
					<!-- <el-dropdown-item>我的</el-dropdown-item> -->
					<el-dropdown-item @click.native="logOut">退出</el-dropdown-item>

				</el-dropdown-menu>
			</el-dropdown>
        

      </span>
    </div>
</template>

<script>
// import netTool from '../api/netTool' 
const trainUrl = process.env.VUE_APP_TRAIN_URL
const trainUrl2 = process.env.VUE_APP_TRAIN_URL2
export default {
  
  data(){
    return{
      // userInfo:{},
      personName:localStorage.getItem('uuid'),
      userType:localStorage.getItem('userType')
    }
  },
  computed:{
      userInfo(){
            return this.$store.state.userInfo;
        },
  },
  mounted(){
        // this.userInfo = this.$store.state.userInfo;
        // this.personName = localStorage.getItem('uuid')
        // this.getOneMajor()
    },

  methods:{
    goRecord(){this.$router.push('/record')},
    goExaminRecord(){this.$router.push('/examinRecord')},
    logOut(){

      if(this.userType == '3'){
        window.location.href = trainUrl
      }else if(this.userType == '7'){
        window.location.href = trainUrl2
      }
      
    }

  }
}
</script>

<style lang="scss" scoped="">
.topTitle{
  width: 100%;
  height:60px;
  line-height:60px;
  background: #232939;
  color: #fff;
  font-size:20px;
  display: flex;
  justify-content: space-between;
  .title{
    padding-left:150px;
    font-size:20px;
  }
  .userInfo{
    padding-right:150px;
    font-size:20px;
  }
}
/deep/ {
  .el-dropdown{
    color: #fff;
    font-size:20px;
  }
}
</style>